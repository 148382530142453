<template>
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '750px' }"
    header="Proveedor Detalle"
    :modal="true"
    @hide="ocultalDialog"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-6">
          <label for="nombre_completo"
            >Nombre Completo: <span class="p-invalid">*</span></label
          >
          <InputText
            id="nombre_completo"
            v-model.trim="data_proveedor.nombre_completo"
            required="true"
            autofocus
            @change="replicarNombreCompleto"
          />
          <small class="p-invalid" v-if="errors.nombre_completo">{{
            errors.nombre_completo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <label for="tipo_proveedor">Tipo Proveedor:</label>
          <Dropdown
            id="tipo_proveedor"
            v-model="data_proveedor.tipo_proveedor"
            :options="tproveedores"
            optionLabel="label"
            placeholder="Seleccione tipo Proveedor"
          >
            <template #value="slotProps">
              <span>{{ slotProps.value.label }}</span>
            </template>
          </Dropdown>
        </div>
        <div class="field col-12 md:col-2">
          <label for="ci_nit">CI/NIT:</label>
          <InputText id="ci_nit" v-model="data_proveedor.ci_nit" />
          <small class="p-invalid" v-if="errors.ci_nit">{{
            errors.ci_nit[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <label for="telefono">Teléfono:</label>
          <InputText
            id="telefono"
            v-model="data_proveedor.telefono"
            required="true"
          />
          <small class="p-invalid" v-if="errors.telefono">{{
            errors.telefono[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6">
          <label for="contacto">Contacto:</label>
          <InputText
            id="contacto"
            v-model="data_proveedor.contacto"
            required="true"
          />
          <small class="p-invalid" v-if="errors.contacto">{{
            errors.contacto[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-6">
          <label for="contacto_telf">Cel. del Contacto:</label>
          <InputText
            id="contacto_telf"
            v-model="data_proveedor.contacto_telf"
            required="true"
          />
          <small class="p-invalid" v-if="errors.contacto_telf">{{
            errors.contacto_telf[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="nombre_cheque">Nombre Cheque:</label>
          <InputText
            id="nombre_cheque"
            v-model="data_proveedor.nombre_cheque"
            required="true"
          />
          <small class="p-invalid" v-if="errors.nombre_cheque">{{
            errors.nombre_cheque[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="nombre_lcv">Nombre LCV:</label>
          <InputText
            id="nombre_lcv"
            v-model="data_proveedor.nombre_lcv"
            required="true"
          />
          <small class="p-invalid" v-if="errors.nombre_lcv">{{
            errors.nombre_lcv[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <label for="estado">Estado:</label>
          <Dropdown
            id="estado"
            v-model="data_proveedor.estado"
            :disabled="!data_proveedor.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado"
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          >Campos Requeridos: <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        class="p-button-secondary"
        @click="ocultalDialog"
        :disabled="!enviado ? false : true"
      />
      <Button
        label="Guardar"
        icon="pi pi-check"
        class="p-button-primary"
        @click="guardarProveedor"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>
</template>

<script>
import ProveedorService from "@/service/ProveedorService";

export default {
  emits: ["closeModal", "actualizarListado"],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    proveedor: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  proveedorService: null,
  data() {
    return {
      mostrarModal: this.show,
      data_proveedor: this.proveedor,
      enviado: false,
      errors: {},
      tproveedores: [
        { label: "Juridico", value: 0 },
        { label: "Natural", value: 1 },
      ],
      estados: [
        { label: "Inactivo", value: 0 },
        { label: "Activo", value: 1 },
      ],
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  created() {
    this.proveedorService = new ProveedorService();
  },
  methods: {
    replicarNombreCompleto() {
      this.data_proveedor.nombre_cheque = this.data_proveedor.nombre_lcv =
        this.data_proveedor.nombre_completo;
    },
    ocultalDialog() {
      this.errors = {};
      this.mostrarModal = false;
      /* this.$emit("actualizarListado"); */
      this.$emit("closeModal");
    },
    guardarProveedor() {
      this.errors = {};
      this.enviado = true;
      if (this.proveedor.id) {
        let proveedor_enviar = {
          ...this.data_proveedor,
          estado: this.data_proveedor.estado.value,
          tipo_proveedor: this.data_proveedor.tipo_proveedor.value,
        };
        //editar Proveedor
        this.proveedorService
          .updatedProveedor(proveedor_enviar)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 3000,
              });
              this.$emit("actualizarListado");
              this.ocultalDialog();
            }
            this.enviado = false;
          });
      } else {
        //Creando Nueva Proveedor
        let tmp = this.data_proveedor;

        this.data_proveedor = {
          ...this.data_proveedor,
          estado: this.data_proveedor.estado.value,
          tipo_proveedor: this.data_proveedor.tipo_proveedor.value,
        };
        this.proveedorService
          .sendProveedorNueva(this.data_proveedor)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
              this.data_proveedor = tmp;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 10000,
              });
              this.$emit("actualizarListado", data.proveedor);
              this.ocultalDialog();
            }
            this.enviado = false;
          });
      }
    },
  },
  watch: {
    show(val) {
      this.mostrarModal = val;
    },
    proveedor(val) {
      this.data_proveedor = val;
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
</style>
