<template>
  <Toast />
  <Dialog
    v-model:visible="mostrarModal"
    :style="{ width: '450px' }"
    @hide="closeModal"
    header="Datos de Marca"
    :modal="true"
    class="p-fluid"
  >
    <div class="field">
      <label for="nombre"
        ><strong>NOMBRE DE MARCA: </strong>
        <span class="p-invalid">*</span></label
      >
      <InputText
        id="nombre"
        v-model="detalle_laboratorio.nombre"
        required="true"
        autofocus
      />
      <small class="p-invalid" v-if="errors.nombre">{{
        errors.nombre[0]
      }}</small>
    </div>
    <div class="field">
      <label for="proveedor_id"
        ><strong>SELECCIONE PROVEEDOR: </strong
        ><span class="p-invalid">*</span></label
      >
      <div class="p-inputgroup">
        <Dropdown
          v-model="proveedorSeleccionado"
          :options="proveedores"
          :filter="true"
          optionLabel="nombre_completo"
          placeholder="Seleccione un proveedor"
        >
          <template #option="slotProps">
            <span>{{ slotProps.option.nombre_completo }}</span>
          </template>
        </Dropdown>
        <Button
          icon="pi pi-plus"
          class="p-button"
          v-tooltip="'Nuevo Proveedor'"
          @click="activarProveedor"
        />
      </div>
      <small class="p-invalid" v-if="errors.proveedor_id">{{
        errors.proveedor_id[0]
      }}</small>
    </div>
    <div class="field" v-if="detalle_laboratorio.id">
      <label for="estado"><strong>ESTADO: </strong></label>
      <Dropdown
        id="estado"
        v-model="detalle_laboratorio.estado"
        :disabled="!detalle_laboratorio.id"
        :options="estados"
        optionLabel="label"
        placeholder="Seleccione un estado"
      >
        <template #value="slotProps">
          <span :class="'product-badge ' + claseEstado[slotProps.value.value]">
            {{ slotProps.value.label }}
          </span>
        </template>
      </Dropdown>
    </div>
    <template #footer>
      <div class="field">
        <label class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></label
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="closeModal"
        :disabled="!enviado ? false : true"
      />
      <Button
        :label="detalle_laboratorio.id ? 'ACTUALIZAR' : 'REGISTRAR'"
        icon="pi pi-save"
        class="p-button-primary p-button-lg"
        @click="registrarLaboratorio"
        :disabled="!enviado ? false : true"
        :loading="enviado"
      />
    </template>
  </Dialog>

  <ProveedorCreate
    :show="proveedorModal"
    :proveedor="proveedor"
    @closeModal="cerrarProveedor"
    @actualizarListado="cargarProveedores"
  ></ProveedorCreate>
</template>

<script>
import LaboratorioService from "@/service/LaboratorioService";
import ProveedorService from "@/service/ProveedorService";
import ProveedorCreate from "@/module/proveedores/ProveedorCreate.vue";

export default {
  name: "LaboratorioCreate",
  emits: ["closeModal", "actualizarListadoLaboratorio"],
  components: {
    ProveedorCreate,
  },
  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
    laboratorio: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      mostrarModal: this.mostrar,
      detalle_laboratorio: this.laboratorio,
      autoFilteredValue: null,
      enviado: false,
      errors: {},
      estados: [
        { label: "Inactivo", value: 0 },
        { label: "Activo", value: 1 },
      ],
      proveedores: [],
      proveedor: {
        estado: { label: "Activo", value: 1 },
        tipo_proveedor: { label: "Natural", value: 1 },
      },
      proveedorModal: false,
      proveedorSeleccionado: {},
      editarLaboratorio: true,
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  laboratorioService: null,
  proveedorService: null,
  created() {
    this.laboratorioService = new LaboratorioService();
    this.proveedorService = new ProveedorService();
  },
  mounted() {
    this.cargarProveedores();
  },
  methods: {
    cargarProveedores(proveedor = null) {
      this.proveedorService.getProveedores().then((data) => {
        this.proveedores = data.proveedores;
        if (proveedor) {
          this.proveedores.forEach((item) => {
            if (item.id == proveedor.id) {
              this.proveedorSeleccionado = item;
            }
          });
        }
      });
    },
    activarProveedor() {
      this.proveedorModal = true;
      this.proveedor = {
        estado: { label: "Activo", value: 1 },
        tipo_proveedor: { label: "Natural", value: 1 },
      };
    },
    cerrarProveedor() {
      this.proveedorModal = false;
      this.proveedor = {};
    },
    closeModal() {
      this.errors = {};
      this.proveedorSeleccionado = {};
      this.detalle_laboratorio = {};
      this.mostrarModal = false;
      this.$emit("closeModal");
    },
    registrarLaboratorio() {
      this.errors = {};
      this.enviado = true;

      if (this.detalle_laboratorio.id) {
        //Actualizar
        let lab = {
          ...this.detalle_laboratorio,
          estado: this.detalle_laboratorio.estado.value,
          proveedor_id: this.proveedorSeleccionado.id,
        };
        this.laboratorioService.updatedLab(lab).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListadoLaboratorio");
            this.$emit("closeModal");
          }
          this.enviado = false;
        });
      } else {
        //registrar

        // verificar que el proveedor seleccionado no sea nulo
        if (this.proveedorSeleccionado == null) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "Debe seleccionar un proveedor",
            life: 3000,
          });
          this.enviado = false;
          return;
        }
        let lab = {
          ...this.detalle_laboratorio,
          estado: this.detalle_laboratorio.estado.value,
          proveedor_id: this.proveedorSeleccionado.id,
        };
        this.laboratorioService
          .sendNuevoLaboratorio(lab)
          .then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "errors")) {
              this.errors = data.errors;
            } else {
              this.errors = {};
              this.$toast.add({
                severity: "success",
                summary: "Exito!",
                detail: data.mensaje,
                life: 3000,
              });
              this.$emit("actualizarListadoLaboratorio", data.fabrica);
              this.$emit("closeModal");
            }
            this.enviado = false;
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    textoEstado(estado) {
      return estado == 1 ? "Activo" : "Inactivo";
    },
  },
  watch: {
    mostrar(newVal) {
      this.mostrarModal = newVal;
    },
    laboratorio(newVal) {
      this.detalle_laboratorio = newVal;
      this.proveedorSeleccionado = //en la ultima posicion
        this.proveedores.length > 0
          ? this.proveedores[this.proveedores.length - 1]
          : {};
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style>
